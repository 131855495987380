import React from 'react';
import Layout from '../components/layout';
import StellenPage from '../components/StellenPage/stellenPage';
import SEO from '../components/SEO/SEO';

const Stellen = () => {
  return (
    <Layout>
      <SEO
        title='Offene Stellen'
        description='Offene Stellen bei Bowman Automobile AG.'
      />
      <StellenPage />
    </Layout>
  );
};

export default Stellen;
